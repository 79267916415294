import { useEffect, useState } from 'react'

interface PropsUseCreateElement {
	where: string
	type?: string
	children?: any
	insertOnTop?: boolean
	[key: string]: any
}

const useCreateElement = ({
	where = 'head',
	type = 'script',
	children,
	insertOnTop,
	...props
}: PropsUseCreateElement) => {
	const element: any = typeof document !== 'undefined' ? document.createElement(type) : null
	const [options, setOptions] = useState<object>()

	useEffect(() => {
		if (element) {
			if (children) element.innerHTML = children.trim()
			const opts: any = { ...props, ...options, id: props.id }
			if (opts) {
				// se reatribuisse usasse um spread, o objeto html se transformaria em objeto convencional
				Object.keys(opts).forEach(key => {
					element[key] = opts[key]
				})
			}

			const hasElement = document.getElementById(props.id)

			if (!hasElement) {
				// @ts-ignore
				if (insertOnTop) document[where].insertBefore(element, document[where].childNodes[0])
				// @ts-ignore
				else document[where].appendChild(element)
			}
		}

		// el = element
		// return () => {
		// 	// @ts-ignore
		// 	document[where].removeChild(element)
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [where, type, children, insertOnTop, props, options])

	return [element, setOptions]
}

export default useCreateElement
