/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import useCreateElement from '../../hooks/useCreateElement'
// import { useStaticQuery, graphql } from 'gatsby'
// import useCreateElement from '@monorepo-amais/commons/hooks/useCreateElement'

function SEO({
	description,
	lang,
	meta = [],
	title,
	image,
	imageDescription,
	fromCosmic = { titulo: '', descricao: '', imagem: { imgix_url: '' }, imagem_descricao: '' }
}: any) {
	useCreateElement({
		id: 'tag-gtm-id-head',
		insertOnTop: true,
		where: 'head',
		type: 'script',
		async: true,
		children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${process.env.GATSBY_GTM_ID}');`
	})
	useCreateElement({
		id: 'tag-gtm-id-body',
		insertOnTop: true,
		where: 'body',
		type: 'noscript',
		async: true,
		children: `<iframe
					title='frame-gtm'
					src='https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM_ID}'
					height='0'
					width='0'
					style='display:none;visibility:hidden'
				></iframe>`
	})

	useCreateElement({
		id: 'tag-analytics-id',
		insertOnTop: true,
		where: 'head',
		type: 'script',
		async: true,
		children: `window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${process.env.GATSBY_ANALYTICS_ID}');`
	})
	useCreateElement({
		id: 'tag-analytics-id-1',
		insertOnTop: true,
		where: 'head',
		type: 'script',
		async: true,
		src: `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_ANALYTICS_ID}`
	})
	// const { site } = useStaticQuery(
	// 	graphql`
	// 		query {
	// 			site {
	// 				siteMetadata {
	// 					title
	// 					description
	// 					author
	// 				}
	// 			}
	// 		}
	// 	`
	// )

	// const metaDescription = description || site.siteMetadata.description

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			meta={[
				{
					property: 'og:type',
					content: 'website'
				},
				{
					name: 'twitter:card',
					content: 'summary'
				},

				{
					name: 'og:image:type',
					content: 'image/png'
				}
				// {
				// 	name: 'description',
				// 	content: fromCosmic.descricao || metaDescription
				// },
				// {
				// 	property: 'og:image',
				// 	content: image
				// },
				// {
				// 	property: 'og:title',
				// 	content: title || fromCosmic.titulo
				// },
				// {
				// 	property: 'og:description',
				// 	content: fromCosmic.descricao || metaDescription
				// },
				// {
				// 	name: 'twitter:creator',
				// 	content: site.siteMetadata.author
				// },
				// {
				// 	name: 'twitter:title',
				// 	content: fromCosmic.titulo || title
				// },
				// {
				// 	name: 'og:image',
				// 	content: fromCosmic.imagem.imgix_url || image
				// },
				// {
				// 	name: 'og:image:alt',
				// 	content: fromCosmic.imagem_descricao || imageDescription
				// }
			].concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	lang: 'pt',
	meta: [],
	description: '',
	image: ''
}

SEO.propTypes = {
	description: PropTypes.string,
	image: PropTypes.node,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string
}

export default SEO
